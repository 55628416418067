.App {
  text-align: center;
}

.headerContainer {
  /* width: 400px; */
  margin-top: 50px;
  margin-bottom: 100px;
}

.formContainer {
  margin-bottom: 100px;
}

.logoImg {
  width: 100%;
  margin-top: 100px;
}

.formSelect {
  width: 200px;
  margin: 25px auto 25px auto;
}

.charName {
  font-size: 2rem;
}

.quoteContainer {
  display: grid;
  place-items: center;
}

.generatorContainer {
  text-align: center;
}

.quoteText {
  font-size: 1.6rem;
}

footer {
  margin-top: 50px;
  margin-bottom: 50px;
}
